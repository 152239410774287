'use strict';

angular.module('enervexSalesappApp').factory('Product', function ($resource) {
	return $resource('/api/products/:id/:import/:selector/:fields', {
		id: '@_id'
	},
	{
		update: {
			method: 'PUT'
		},
		import: {
			method: 'POST',
			timeout: 340000,
			transformRequest: angular.identity,
			headers: {
				'Content-Type': undefined,
				'Accept': "application/json"
			},
			params:{
				import: "import"
			}
		},
		fields: {
			isArray: true,
			params:{
				fields: "fields"
			}
		},
		inlineFans: {
			isArray: true,
			params:{
				selector: "inline-fans"
			}
		},
		economizers: {
			isArray: true,
			params:{
				limit: "1000",
				type: "5cd59b1c93e81f8671dbea46"
			}
		},
	});
});
